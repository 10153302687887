<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="listcard" :loading="loading">
          <h2 v-if="!!!items">Keine Lieferscheine gefunden</h2>
          <v-data-table
            :headers="headers"
            :items="items"
            :sort-by="['deliverynr']"
            :sort-desc="[true]"
            :itemsPerPage="-1"
            multi-sort
          >
            <template slot="item.date" slot-scope="{ item }">
              <span>{{ formattedDate(item.date) }}</span>
            </template>

            <template slot="item.status" slot-scope="{ item }">
              <v-chip small :color="statusColor(item.status)">
                {{ statusTitle(item.status) }}
              </v-chip>
            </template>

            <template slot="item.totalNetto" slot-scope="{ item }">
              <span> {{ currencyFormat(item.totalNetto) }} </span>
            </template>

            <template slot="item.totalBrutto" slot-scope="{ item }">
              <span> {{ currencyFormat(item.totalBrutto) }} </span>
            </template>
            <template slot="item.createdBy" slot-scope="{ item }">
              <span>{{ userKuerzel(item.createdBy) }}</span>
            </template>

            <template slot="item.mandant" slot-scope="{ item }">
              {{ mandantName(item.mandant) }}
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <v-menu dense offset-y align="right">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dense v-bind="attrs" v-on="on"
                    >mdi-dots-vertical</v-icon
                  >
                </template>
                <v-list dense>
                  <v-list-item
                    @click="editItem(item)"
                    v-if="!!!isInvoiced(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    Bearbeiten
                  </v-list-item>
                  <v-list-item
                    @click="askDeleteItem(item)"
                    v-if="!!isEntwurf(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    Löschen
                  </v-list-item>
                  <v-list-item @click="downloadPdf(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-download</v-icon> </v-list-item-icon
                    >PDF anzeigen</v-list-item
                  >
                  <v-list-item
                    @click="deliverySend(item)"
                    v-if="!!!isEntwurf(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-email</v-icon>
                    </v-list-item-icon>
                    Lieferschein senden</v-list-item
                  >
                  <v-list-item
                    @click="createDelivery(item)"
                    v-if="!!isEntwurf(item) || !!isCompleted(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-truck-delivery</v-icon> </v-list-item-icon
                    >Liefern</v-list-item
                  >

                  <v-list-item
                    v-if="!!!isInvoiced(item)"
                    @click="createBill(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-cash</v-icon>
                    </v-list-item-icon>
                    Rechnung erstellen</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Lieferschein löschen?</v-card-title>
        <v-card-text
          >Soll der Lieferschein wirklich gelöscht werden?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteItem">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MailPreviewDialog ref="mailDialog" />
  </v-container>
</template>

<script>
import moment from "moment";
import MailPreviewDialog from "@/components/MailPreviewDialog";
import PDFTemplateService from "@/services/PDFTemplateService.js";
import itemStatus from "@/helpers/itemStatus.js";

export default {
  name: "DeliveryList",
  data: () => ({
    loading: false,
    search: "",
    dlgDelete: false,
    selectedItem: false,
    headers: [
      { text: "Status", value: "status" },
      { text: "Nr", value: "deliverynr" },
      { text: "Kdnr", value: "customer.kdnr" },
      { text: "Kunde", value: "customer.name1" },
      { text: "Datum", value: "date" },
      // { text: "Produkte", value: "products.length" },
      { text: "Mandant", value: "mandant" },
      { text: "Ersteller", value: "createdBy" },
      { text: "Netto", value: "totalNetto", align: "right" },
      { text: "Brutto", value: "totalBrutto", align: "right" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "110px",
        align: "right",
      },
    ],
  }),
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: { MailPreviewDialog },
  methods: {
    createDelivery(item) {
      item.status = itemStatus.Delivered.code;
      this.saveDelivery(item);
    },
    createBill(item) {
      item.status = itemStatus.Invoiced.code;
      return this.saveBill(item).then(() => {
        item.templateID = null;
        return this.saveDelivery(item);
      });
    },

    isEntwurf(item) {
      return item.status == itemStatus.Draft.code;
    },
    isCompleted(item) {
      return item.status == itemStatus.Completed.code;
    },
    isAccepted(item) {
      return item.status == itemStatus.Accepted.code;
    },
    isDelivered(item) {
      return item.status == itemStatus.Delivered.code;
    },
    isInvoiced(item) {
      return item.status == itemStatus.Invoiced.code;
    },
    downloadPdf(item) {
      this.$store.commit("SET_APP_LOADING", true);
      PDFTemplateService.getPdfByObjectId(item._id, "delivery")
        .then((response) => {
          let FileSaver = require("file-saver");
          let filename = response.data.data.filename;
          const bytes = new Uint8Array(response.data.data.pdfData.data);
          FileSaver.saveAs(
            new Blob([bytes], {
              type: "application/pdf;",
            }),
            filename
          );
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler beim Download",
              text: "PDF Datei konnte nicht geladen werden.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    },
    saveDelivery(item) {
      return this.$store
        .dispatch("delivery/saveDelivery", item)
        .then((result) => {
          this.$notify({
            group: "dataline",
            type: "info",
            title: "Status Update",
            text:
              "Lieferschein " + result.deliverynr + " erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          if (error.response && error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.response.data.error,
            });
          }
        });
    },
    saveBill(item) {
      return this.$store
        .dispatch("delivery/fetchDelivery", {
          id: item._id,
          mandant: item.mandant,
        })
        .then((result) => {
          let bill = result.data.data;
          const mlist = this.$store.getters["mandant/getMandantenList"];
          const mItem = mlist.find((el) => el._id == item.mandant);
          if (!bill.origins) {
            bill.origins = {};
          }
          bill.origins.delivery = { id: item._id, nr: item.deliverynr };
          bill.status = itemStatus.Draft.code;
          bill.userKuerzel = this.$store.getters["user/getUser"].kuerzel;
          return this.$store.dispatch("bill/saveNewBill", {
            item: bill,
            mandant: mItem,
          });
        })
        .then((result) => {
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Rechnung erstellt",
            text: "Rechnung " + result.billnr + " erfolgreich erstellt",
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler beim erstellen der Rechnung",
              text: error.message,
            });
          }
        });
    },
    statusTitle(s) {
      let status = "Neu";

      for (const key of Object.keys(itemStatus)) {
        if (itemStatus[key].code == s) {
          status = itemStatus[key].title;
        }
      }

      return status;
    },
    deliverySend(item) {
      item.type = "delivery";
      this.$refs.mailDialog.setDataItem(item);
      this.$refs.mailDialog.showDialog();
    },
    getMandant(mid) {
      let mandant = this.$store.getters["mandant/getMandantenList"].find(
        (item) => item._id == mid
      );
      if (mandant) {
        return mandant;
      } else {
        return null;
      }
    },
    mandantName(mid) {
      let mandant = this.getMandant(mid);
      if (mandant) {
        return mandant.firmenname;
      } else {
        return "";
      }
    },
    statusColor(status) {
      let color = "";
      if (status == itemStatus.Delivered.code) {
        color = "success";
      }
      return color;
    },
    askDeleteItem(item) {
      this.dlgDelete = true;
      this.selectedItem = item;
    },
    editItem(item) {
      this.$router.push({ name: "delivery-edit", params: { id: item._id } });
    },

    formattedDate(dt) {
      return moment(dt).format("DD.MM.YYYY");
    },
    formattedDateTime(dt) {
      return moment(dt).format("DD.MM.YYYY HH:mm:ss");
    },
    userKuerzel(email) {
      let user = this.$store.getters["user/getUserList"].find(
        (item) => item.email == email
      );
      if (user) {
        return user.kuerzel;
      } else {
        return email;
      }
    },
    deleteItem() {
      this.dlgDelete = false;
      this.$store
        .dispatch("delivery/deleteItem", this.selectedItem)
        .then((result) => {
          if (result) {
            this.$notify({
              group: "dataline",
              type: "info",
              title: "Lieferschein gelöscht",
              text:
                "Lieferschein " +
                this.selectedItem.deliverynr +
                " wurde gelöscht",
            });
            this.selectedItem = null;
          }
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: "Fehler beim löschen des Lieferscheins. " + error.message,
            });
          }
        });
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    toggleMandantHeader() {
      const colMandant = { text: "Mandant", value: "mandant" };
      const index = this.headers.findIndex(function (item) {
        return item.value === colMandant.value;
      });
      //Only show Mandant column on all selected
      if (this.$store.getters["mandant/getWorkingMandant"] == "all") {
        if (index == -1) {
          this.headers.splice(5, 0, colMandant);
        }
      } else {
        if (index > -1) {
          this.headers.splice(index, 1);
        }
      }
    },
  },

  computed: {},

  created() {},
};
</script>

<style scoped>
.detailtitle {
  font-weight: bold;
}
.mandant {
  font-size: 16px;
  font-weight: bold;
}
#listcard {
  margin: 20px;
}
</style>
