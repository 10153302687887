<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="listcard" :loading="loading">
          <h2 v-if="!!!items">Keine Auftragsbestätigunge gefunden</h2>
          <v-data-table
            :headers="headers"
            :items="items"
            :sort-by="['confirmnr']"
            :sort-desc="[true]"
            :itemsPerPage="-1"
            multi-sort
          >
            <template slot="item.date" slot-scope="{ item }">
              <span>{{ formattedDate(item.date) }}</span>
            </template>

            <template slot="item.totalNetto" slot-scope="{ item }">
              <span> {{ currencyFormat(item.totalNetto) }} </span>
            </template>

            <template slot="item.totalBrutto" slot-scope="{ item }">
              <span> {{ currencyFormat(item.totalBrutto) }} </span>
            </template>

            <template slot="item.createdBy" slot-scope="{ item }">
              <span>{{ userKuerzel(item.createdBy) }}</span>
            </template>

            <template slot="item.status" slot-scope="{ item }">
              <v-chip small :color="statusColor(item.status)">
                {{ statusTitle(item.status) }}
              </v-chip>
            </template>
            <template slot="item.mandant" slot-scope="{ item }">
              {{ mandantName(item.mandant) }}
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <div style="text-align: right">
                <v-menu dense offset-y align="right">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon dense v-bind="attrs" v-on="on"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>
                  <v-list dense>
                    <v-list-item
                      @click="editItem(item)"
                      v-if="!!isEntwurf(item) || !!isCompleted(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      Bearbeiten
                    </v-list-item>
                    <v-list-item
                      @click="askDeleteItem(item)"
                      v-if="!!isEntwurf(item) || isCompleted(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      Löschen
                    </v-list-item>
                    <v-list-item @click="downloadPdf(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-download</v-icon> </v-list-item-icon
                      >PDF anzeigen</v-list-item
                    >
                    <v-list-item
                      @click="confirmCompleted(item)"
                      v-if="!!isEntwurf(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-check</v-icon> </v-list-item-icon
                      >Auftragsbestätigung fertigstellen</v-list-item
                    >

                    <v-list-item
                      @click="confirmSend(item)"
                      v-if="!!!isEntwurf(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-email</v-icon>
                      </v-list-item-icon>
                      Auftragsbestätigung senden</v-list-item
                    >

                    <v-list-item
                      v-if="!!isDelivered || !!isInvoiced"
                      @click="createDelivery(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-truck-delivery</v-icon> </v-list-item-icon
                      >Lieferschein erstellen</v-list-item
                    >
                    <v-divider />
                    <v-list-item
                      v-if="!!!isInvoiced(item)"
                      @click="createBill(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-cash</v-icon>
                      </v-list-item-icon>
                      Rechnung erstellen</v-list-item
                    >
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"
          >Auftragsbestätigung löschen?</v-card-title
        >
        <v-card-text
          >Soll das Auftragsbestätigung wirklich gelöscht werden?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteItem">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MailPreviewDialog ref="mailDialog" />
  </v-container>
</template>

<script>
import moment from "moment";
import PDFTemplateService from "@/services/PDFTemplateService.js";
import itemStatus from "@/helpers/itemStatus.js";
import MailPreviewDialog from "@/components/MailPreviewDialog";

export default {
  name: "ConfirmationList",
  data: () => ({
    loading: false,
    search: "",
    dlgDelete: false,
    dlgDetails: false,
    detailItem: null,
    selectedItem: false,
    headers: [
      { text: "Status", value: "status" },
      { text: "Nr", value: "confirmnr" },
      { text: "Kdnr", value: "customer.kdnr" },
      { text: "Kunde", value: "customer.name1" },
      { text: "Datum", value: "date" },
      // { text: "Produkte", value: "products.length" },
      { text: "Mandant", value: "mandant" },
      { text: "Ersteller", value: "createdBy" },
      { text: "Netto", value: "totalNetto", align: "right" },
      { text: "Brutto", value: "totalBrutto", align: "right" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "110px",
        align: "right",
      },
    ],
  }),
  components: { MailPreviewDialog },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isEntwurf(item) {
      return item.status == itemStatus.Draft.code;
    },
    isCompleted(item) {
      return item.status == itemStatus.Completed.code;
    },
    isAccepted(item) {
      return item.status == itemStatus.Accepted.code;
    },
    isDelivered(item) {
      return item.status == itemStatus.Delivered.code;
    },
    isInvoiced(item) {
      return item.status == itemStatus.Invoiced.code;
    },
    confirmCompleted(item) {
      item.status = itemStatus.Completed.code;
      this.saveConfirmation(item);
    },
    confirmAccepted(item) {
      item.status = itemStatus.Accepted.code;
      this.saveConfirmation(item);
    },
    createDelivery(item) {
      item.status = itemStatus.Delivered.code;
      return this.saveDelivery(item).then(() => {
        item.templateID = null;
        return this.saveConfirmation(item);
      });
    },
    createBill(item) {
      item.status = itemStatus.Invoiced.code;
      return this.saveBill(item).then(() => {
        item.templateID = null;
        return this.saveConfirmation(item);
      });
    },
    createDeliveryBill(item) {
      item.status = itemStatus.Invoiced.code;
      this.saveConfirmation(item, true, true);
    },
    downloadPdf(item) {
      this.$store.commit("SET_APP_LOADING", true);
      PDFTemplateService.getPdfByObjectId(item._id, "confirmation")
        .then((response) => {
          if (response.data.data.pdfData == null) {
            throw new Error("Kein Pdf vorhanden");
          }
          let FileSaver = require("file-saver");
          let filename = response.data.data.filename;
          const bytes = new Uint8Array(response.data.data.pdfData.data);
          FileSaver.saveAs(
            new Blob([bytes], {
              type: "application/pdf;",
            }),
            filename
          );
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler beim Download",
              text: "PDF Datei konnte nicht geladen werden.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    },
    confirmSend(item) {
      item.type = "confirmation";
      this.$refs.mailDialog.setDataItem(item);
      this.$refs.mailDialog.showDialog();
    },
    getMandant(mid) {
      let mandant = this.$store.getters["mandant/getMandantenList"].find(
        (item) => item._id == mid
      );
      if (mandant) {
        return mandant;
      } else {
        return null;
      }
    },
    mandantName(mid) {
      let mandant = this.getMandant(mid);
      if (mandant) {
        return mandant.firmenname;
      } else {
        return "";
      }
    },

    saveDelivery(item) {
      return this.$store
        .dispatch("confirmation/fetchConfirmation", {
          id: item._id,
          mandant: item.mandant,
        })
        .then((result) => {
          let delivery = result.data.data;
          const mlist = this.$store.getters["mandant/getMandantenList"];
          const mItem = mlist.find((el) => el._id == item.mandant);
          if (!delivery.origins) {
            delivery.origins = {};
          }
          delivery.origins.confirmation = { id: item._id, nr: item.confirmnr };
          delivery.status = itemStatus.Delivered.code;
          delivery.userKuerzel = this.$store.getters["user/getUser"].kuerzel;
          return this.$store.dispatch("delivery/saveNewDelivery", {
            item: delivery,
            mandant: mItem,
          });
        })
        .then((result) => {
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Lieferschein erstellt",
            text: "Lieferschein " + result.deliverynr + " erfolgreich erstellt",
          });
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler beim erstellen des Lieferscheins",
              text: error.message,
            });
          }
        });
    },

    saveBill(item) {
      return this.$store
        .dispatch("confirmation/fetchConfirmation", {
          id: item._id,
          mandant: item.mandant,
        })
        .then((result) => {
          let bill = result.data.data;
          const mlist = this.$store.getters["mandant/getMandantenList"];
          const mItem = mlist.find((el) => el._id == item.mandant);
          if (!bill.origins) {
            bill.origins = {};
          }
          bill.origins.confirmation = { id: item._id, nr: item.confirmnr };
          bill.status = itemStatus.Draft.code;
          bill.userKuerzel = this.$store.getters["user/getUser"].kuerzel;
          return this.$store.dispatch("bill/saveNewBill", {
            item: bill,
            mandant: mItem,
          });
        })
        .then((result) => {
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Rechnung erstellt",
            text: "Rechnung " + result.billnr + " erfolgreich erstellt",
          });
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler beim erstellen der Rechnung",
              text: error.message,
            });
          }
        });
    },

    saveConfirmation(item) {
      return this.$store
        .dispatch("confirmation/saveConfirmation", item)
        .then((result) => {
          this.$notify({
            group: "dataline",
            type: "info",
            title: "Status Update",
            text:
              "Auftragsbestätigung " +
              result.confirmnr +
              " erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.response.data.error,
            });
          }
        });
    },
    statusTitle(s) {
      let status = "Neu";

      for (const key of Object.keys(itemStatus)) {
        if (itemStatus[key].code == s) {
          status = itemStatus[key].title;
        }
      }

      return status;
    },
    statusColor(status) {
      let color = "";

      if (
        status == itemStatus.Confirmed.code ||
        status == itemStatus.Delivered.code ||
        status == itemStatus.Invoiced.code
      ) {
        color = "success";
      }
      return color;
    },
    askDeleteItem(item) {
      this.dlgDelete = true;
      this.selectedItem = item;
    },
    editItem(item) {
      this.$router.push({
        name: "confirmation-edit",
        params: { id: item._id },
      });
    },

    formattedDate(dt) {
      return moment(dt).format("DD.MM.YYYY");
    },
    formattedDateTime(dt) {
      return moment(dt).format("DD.MM.YYYY HH:mm:ss");
    },
    userKuerzel(email) {
      let user = this.$store.getters["user/getUserList"].find(
        (item) => item.email == email
      );
      if (user) {
        return user.kuerzel;
      } else {
        return email;
      }
    },
    deleteItem() {
      this.dlgDelete = false;
      this.$store
        .dispatch("confirmation/deleteItem", this.selectedItem)
        .then((result) => {
          if (result) {
            this.$notify({
              group: "dataline",
              type: "success",
              title: "Auftragsbestätigung gelöscht",
              text:
                "Auftragsbestätigung " +
                this.selectedItem.confirmnr +
                " wurde gelöscht",
            });

            this.selectedItem = null;
          }
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text:
                "Fehler beim löschen des Auftragsbestätigunges. " +
                error.message,
            });
          }
        });
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    toggleMandantHeader() {
      const colMandant = { text: "Mandant", value: "mandant" };
      const index = this.headers.findIndex(function (item) {
        return item.value === colMandant.value;
      });
      //Only show Mandant column on all selected
      if (this.$store.getters["mandant/getWorkingMandant"] == "all") {
        if (index == -1) {
          this.headers.splice(5, 0, colMandant);
        }
      } else {
        if (index > -1) {
          this.headers.splice(index, 1);
        }
      }
    },
  },

  computed: {},

  created() {},
};
</script>

<style scoped>
.detailtitle {
  font-weight: bold;
}
.mandant {
  font-size: 16px;
  font-weight: bold;
}
#listcard {
  margin: 20px;
}
</style>
