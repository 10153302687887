var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"dense":"","color":"secondary"},on:{"click":_vm.importDialog}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v("mdi-import")]),_c('span',[_vm._v("Importieren")])],1),_c('v-dialog',{attrs:{"modal":true,"width":"500px"},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"grey lighten-2"},[_vm._v("CSV Import")]),_c('v-card-text',[_c('MandantenSelectbox',{attrs:{"color":"purple","showall":true},model:{value:(_vm.mandant),callback:function ($$v) {_vm.mandant=$$v},expression:"mandant"}}),_c('VuetifyCsvImport',{staticClass:"pa-4",attrs:{"autoMatchFields":true,"autoMatchIgnoreCase":true,"canIgnore":true,"map-fields":_vm.mapFields,"tableClass":"importTable","callback":_vm.importData},scopedSlots:_vm._u([{key:"hasHeaders",fn:function(ref){
var headers = ref.headers;
var toggle = ref.toggle;
return [_c('v-switch',{attrs:{"id":"hasHeaders","name":"hasHeaders","value":headers,"label":"Überschrften in CSV?"},on:{"change":toggle}})]}},{key:"next",fn:function(ref){
var load = ref.load;
return [_c('v-btn',{staticClass:"ma-4",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return load.apply(null, arguments)}}},[_vm._v("Datei laden und Felder zuordnen")])]}},{key:"submit",fn:function(ref){
var submit = ref.submit;
return [_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return submit.apply(null, arguments)}}},[_vm._v("Importieren!")])]}}]),model:{value:(_vm.parseCsv),callback:function ($$v) {_vm.parseCsv=$$v},expression:"parseCsv"}},[_c('template',{slot:"error"},[_vm._v("File type is invalid")]),_c('template',{slot:"thead"},[_c('tr',[_c('th',[_vm._v("CRM Feldname")]),_c('th',[_vm._v("CSV Spaltenname")])])])],2),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"purple"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.closeDialog}},[_vm._v("Abbrechen")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }