<template>
  <v-container fluid>
    <v-col cols="12" sm="12" offset-sm="0">
      <v-card class="maincard">
        <v-container>
          <v-row align="center">
            <v-col cols="12" xs="12" sm="6">
              <v-card-title>Kontaktverwaltung</v-card-title>
            </v-col>
            <v-col cols="12" xs="12" sm="3" style="text-align: right">
              <ImportDialog
                v-if="
                  this.checkAccess(
                    this.$store.getters['user/userRoles'].userRole.Manager
                  )
                "
                importUrl="/contact/import"
                :mapFields="mapFields"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="3" style="text-align: right">
              <v-btn to="/kontakt/edit" color="success">
                <v-icon style="margin-right: 10px">mdi-account-plus</v-icon>
                <span>Neuer Kontakt</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <KontaktList />
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
// @ is an alias to /src
import KontaktList from "@/components/contacts/KontaktList";
import ImportDialog from "@/components/contacts/ContactImportDialog";

export default {
  name: "KontaktMgmt",
  data: () => ({
    mapFields: {
      kdnr: "Kdnr",
      anrede: "Anrede",
      titel: "Titel",
      name1: "Name1",
      name2: "Name2",
      adresse: "Adresse",
      plz: "Plz",
      ort: "Ort",
      land: "Land",
      email: "Email",
      web: "Website",
      telefon: "Telefon",
      fax: "Fax",
      uid: "UID Nr.",
    },
  }),
  components: { KontaktList, ImportDialog },
  methods: {
    checkAccess(role) {
      const user = this.$store.getters["user/getUser"];

      if (!user) {
        return false;
      }
      if (
        user.apps.roles.indexOf(
          this.$store.getters["user/userRoles"].userRole.SuperUser
        ) >= 0
      ) {
        return true;
      } else if (user.apps.roles.indexOf(role) >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
};
</script>
<style scoped></style>
