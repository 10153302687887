<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="contactlistcard" :loading="loading">
          <h2 v-if="!!!contactList">Keine Kontakte gefunden</h2>
          <v-data-table
            :headers="headers"
            :items="contactList"
            :items-per-page="-1"
            :page.sync="currentPage"
            multi-sort
            hide-default-footer
          >
            <template v-slot:top>
              <v-text-field
                v-model="contactSearch"
                label="Kundensuche"
                class="pa-4"
                :outlined="true"
                :filled="true"
                :clearable="true"
                background-color="#e8eff7"
                @keyup.enter="loadContacts"
                @click:clear="clearedSearch"
              ></v-text-field>
            </template>

            <template slot="item.actions" slot-scope="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small class="mr-2" @click="askDeleteKontakt(item)"
                >mdi-delete</v-icon
              >
              <v-icon small class="mr-2" @click="infoItem(item)"
                >mdi-information</v-icon
              >
            </template>
          </v-data-table>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2"> </v-col>
              <v-col cols="12" sm="8">
                <v-pagination
                  circle
                  v-model="currentPage"
                  :length="totalPages"
                  :total-visible="6"
                ></v-pagination>
              </v-col>
              <v-col cols="12" sm="2" class="d-flex justify-end">
                <v-text-field
                  class="textPerPage"
                  label="Einträge pro Seite"
                  type="number"
                  min="10"
                  max="200"
                  v-model="perPage"
                  @blur="loadContacts"
                  @keyup.enter="loadContacts"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Kontakt löschen?</v-card-title>
        <v-card-text>
          Soll der Kontakt
          <strong>{{ deleteKontaktName }}</strong> wirklich gelöscht werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteContact">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dlgDetails"
      max-width="90%"
      @keydown.esc="dlgDetails = false"
    >
      <v-card tile v-if="detailItem">
        <v-toolbar dark>
          <v-toolbar-title>Kontaktdetails</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dlgDetails = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <h2 class="">Kunde:</h2>
            <v-row>
              <v-col cols="12" sm="4">
                <h3 class="ma-0 pa-0 mb-2">Kundendaten</h3>
                <span class="detailtitle">Kdnr.:</span>
                {{ detailItem.customer.kdnr }}<br />
                <span class="detailtitle">Titel:</span>
                {{ detailItem.customer.titel }}<br />
                <span class="detailtitle">Anrede:</span>
                {{ detailItem.customer.anrede }}<br />
                <span class="detailtitle">Name1:</span>
                {{ detailItem.customer.name1 }} <br />
                <span class="detailtitle">Name2:</span>
                {{ detailItem.customer.name2 }} <br />
                <span class="detailtitle">Adresse:</span>
                {{ detailItem.customer.adresse }}<br />
                <span class="detailtitle">PLZ/Ort:</span>
                {{ detailItem.customer.plz }} -
                {{ detailItem.customer.ort }}
                <br />
                <span class="detailtitle">Land:</span>
                {{ detailItem.customer.land }}<br />
                <span class="detailtitle" v-if="detailItem.customer.uid"
                  >UID: {{ detailItem.customer.uid }}</span
                >
              </v-col>
              <v-col cols="12" sm="4">
                <h3 class="ma-0 pa-0 mb-2">Kontaktdaten</h3>
                <span class="detailtitle">Email:</span>
                {{ detailItem.customer.email }}<br />
                <span class="detailtitle">Telefon:</span>
                {{ detailItem.customer.telefon }} <br />
                <span class="detailtitle">Fax:</span>
                {{ detailItem.customer.fax }} <br />
                <span class="detailtitle">Web:</span>
                {{ detailItem.customer.web }}<br />
              </v-col>
              <v-col cols="12" sm="4">
                <h3 class="ma-0 pa-0 mb-2"></h3>
                <span class="detailtitle">Ersteller:</span>
                {{ detailItem.createdBy }}<br />
                <span class="detailtitle">Erstellungsdatum:</span>
                {{ formattedDateTime(detailItem.createdTS) }}
                <br />
                <span class="detailtitle">Bearbeitet:</span>
                {{ detailItem.createdBy }}<br />
                <span class="detailtitle">Bearbeitungsdatum:</span>
                {{ formattedDateTime(detailItem.modifiedTS) }} <br />
              </v-col>
            </v-row>
          </v-container>
          <v-toolbar color="grey lighten-3">
            <v-tabs v-model="tabs" fixed-tabs color="purple">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                href="#contactOffers"
                class="primary--text"
                @click="loadOffers"
              >
                <v-icon class="mr-4">mdi-offer</v-icon> Angebote
              </v-tab>

              <v-tab
                href="#contactConfirmations"
                class="primary--text"
                @click="loadConfirmations"
              >
                <v-icon class="mr-4">mdi-text-box-check</v-icon> ABs
              </v-tab>

              <v-tab
                href="#contactDeliveries"
                class="primary--text"
                @click="loadDeliveries"
              >
                <v-icon class="mr-4">mdi-truck-delivery</v-icon> Lieferscheine
              </v-tab>
              <v-tab
                href="#contactBills"
                class="primary--text"
                @click="loadBills"
              >
                <v-icon class="mr-4">mdi-cash</v-icon> Rechnungen
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="contactOffers">
              <v-card :loading="loadingOffers">
                <v-card-text
                  ><ContactOfferList :items="offerList"
                /></v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="contactConfirmations">
              <v-card :loading="loadingConfirmations">
                <v-card-text
                  ><ContactConfirmationList :items="confirmationList"
                /></v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="contactDeliveries">
              <v-card :loading="loadingDeliveries">
                <v-card-text
                  ><ContactDeliveryList :items="deliveryList"
                /></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="contactBills">
              <v-card :loading="loadingBills">
                <v-card-text
                  ><ContactBillList :items="offerList"
                /></v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            outlined
            text
            @click="dlgDetails = false"
            >Schließen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DataService from "@/services/ContactService.js";
import OfferService from "@/services/OfferService.js";
import DeliveryService from "@/services/DeliveryService.js";
import BillService from "@/services/BillService.js";
import ConfirmationService from "@/services/ConfirmationService.js";
import moment from "moment";
import ContactOfferList from "@/components/offers/ContactOfferList";
import ContactConfirmationList from "@/components/confirmations/ContactConfirmationList";
import ContactDeliveryList from "@/components/deliverynotes/ContactDeliverynotesList";
import ContactBillList from "@/components/bills/ContactBillList";

export default {
  name: "KontaktList",
  data: () => ({
    loading: false,
    awaitSearch: false,
    loadingOffers: false,
    loadingConfirmations: false,
    loadingDeliveries: false,
    loadingBills: false,
    offerList: [],
    confirmationList: [],
    deliveryList: [],
    billList: [],
    search: "",
    tabs: null,
    detailItem: null,
    dlgDelete: false,
    dlgDetails: false,
    deleteItem: false,
    deleteKontaktName: "",
    headers: [
      { text: "Kdnr", value: "kdnr" },
      { text: "Anrede", value: "anrede" },
      { text: "Name", value: "name1" },
      { text: "Adresse", value: "adresse" },
      { text: "Plz", value: "plz" },
      { text: "Ort", value: "ort" },
      { text: "Telefon", value: "telefon", width: "110px" },
      { text: "Email", value: "email", width: "110px" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "110px",
        align: "right",
      },
    ],
  }),
  components: {
    ContactOfferList,
    ContactConfirmationList,
    ContactDeliveryList,
    ContactBillList,
  },
  methods: {
    clearedSearch() {
      this.contactSearch = "";
      this.loadContacts();
    },
    askDeleteKontakt(item) {
      this.deleteKontaktName = item.name1;
      this.dlgDelete = true;
      this.deleteItem = item;
    },
    editItem(item) {
      this.$router.push({ name: "kontakt-edit", params: { id: item._id } });
    },
    infoItem(item) {
      this.dlgDetails = true;
      this.tabs = "contactOffers";
      this.detailItem = {
        customer: item,
      };
      DataService.get(item._id)
        .then((response) => {
          if (response.data) {
            this.detailItem.customer = response.data.data;
          }
        })
        .then(() => {
          return this.loadOffers();
        })
        .catch((error) => {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler bei Kundenabfrage",
            text:
              "Die Kundendaten konnten nicht abgefragt werden: " +
              error.message,
          });
        });
    },
    formattedDate(dt) {
      return moment(dt).format("DD.MM.YYYY");
    },
    formattedDateTime(dt) {
      return moment(dt).format("DD.MM.YYYY HH:mm:ss");
    },
    userKuerzel(email) {
      let user = this.$store.getters["user/getUserList"].find(
        (item) => item.email == email
      );
      if (user) {
        return user.kuerzel;
      } else {
        return email;
      }
    },
    deleteContact() {
      this.dlgDelete = false;
      DataService.delete(this.deleteItem._id).then(() => {
        this.$notify({
          group: "dataline",
          type: "info",
          title: "Kontakt gelöscht",
          text: "Kontakt " + this.deleteItem.name1 + " wurde gelöscht",
        });
        this.deleteKontaktName = "";
      });
    },
    searchContacts() {
      if (this.loading) return;

      this.loadContacts();
    },
    loadContacts() {
      this.loading = true;
      this.$store
        .dispatch("contact/fetchContactList")
        .then(() => {
          this.loading = false;
          if (this.currentPage > this.totalPages) {
            this.currentPage = this.totalPages;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        });
    },

    loadOffers() {
      console.log("load offers");
      this.loadingOffers = true;
      OfferService.getByContact(this.mandant, this.detailItem.customer._id)
        .then((result) => {
          this.offerList = result.data.items;
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.loadingOffers = false;
        });
    },
    loadConfirmations() {
      this.loadingConfirmations = true;
      ConfirmationService.getByContact(
        this.mandant,
        this.detailItem.customer._id
      )
        .then((result) => {
          this.confirmationList = result.data.items;
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.loadingConfirmations = false;
        });
    },
    loadDeliveries() {
      this.loadingDeliveries = true;
      DeliveryService.getByContact(this.mandant, this.detailItem.customer._id)
        .then((result) => {
          this.deliveryList = result.data.items;
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.loadingDeliveries = false;
        });
    },

    loadBills() {
      this.loadingBills = true;
      BillService.getByContact(this.mandant, this.detailItem.customer._id)
        .then((result) => {
          this.billList = result.data.items;
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.loadingBills = false;
        });
    },
  },

  computed: {
    mandant() {
      return this.$store.getters["mandant/getWorkingMandant"];
    },

    contactList() {
      return this.$store.getters["contact/getContactList"];
    },
    totalPages() {
      return this.$store.getters["contact/getTotalPages"];
    },
    contactSearch: {
      get() {
        return this.$store.getters["contact/getSearch"];
      },
      set(query) {
        this.$store.commit("contact/SET_SEARCH", query);
      },
    },
    perPage: {
      get() {
        return this.$store.getters["contact/getPerPage"];
      },
      set(value) {
        this.$store.commit("contact/SET_LIST_PER_PAGE", value);
      },
    },
    currentPage: {
      get() {
        return this.$store.getters["contact/getPage"];
      },
      set(page) {
        this.$store.dispatch("contact/fetchContactList", page);
      },
    },
  },
  watch: {
    contactSearch() {
      if (!this.awaitSearch) {
        setTimeout(() => {
          this.loadContacts();
          this.awaitSearch = false;
        }, 500);
      }
      this.awaitSearch = true;
    },
  },

  created() {
    this.loadContacts();
  },
};
</script>

<style scoped>
#kontaktlistcard {
  margin: 20px;
}
.textPerPage {
  width: 100px !important;
  max-width: 100px !important;
}
</style>
