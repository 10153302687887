<template>
  <v-container dense>
    <v-spacer></v-spacer>
    <v-btn dense @click="importDialog" color="secondary">
      <v-icon style="margin-right: 10px;">mdi-import</v-icon>
      <span>Importieren</span>
    </v-btn>
    <v-dialog v-model="dialogStatus" :modal="true" width="500px">
      <v-card :loading="loading">
        <v-card-title class="grey lighten-2">CSV Import</v-card-title>
        <v-card-text>
          <MandantenSelectbox
            color="purple"
            v-model="mandant"
            :showall="true"
          />
          <VuetifyCsvImport
            class="pa-4"
            :autoMatchFields="true"
            :autoMatchIgnoreCase="true"
            :canIgnore="true"
            v-model="parseCsv"
            :map-fields="mapFields"
            tableClass="importTable"
            :callback="importData"
          >
            <template slot="hasHeaders" slot-scope="{ headers, toggle }">
              <v-switch
                id="hasHeaders"
                name="hasHeaders"
                :value="headers"
                @change="toggle"
                label="Überschrften in CSV?"
              />
            </template>

            <template slot="error">File type is invalid</template>

            <template slot="thead">
              <tr>
                <th>CRM Feldname</th>
                <th>CSV Spaltenname</th>
              </tr>
            </template>

            <template slot="next" slot-scope="{ load }">
              <v-btn class="ma-4" @click.prevent="load" color="primary"
                >Datei laden und Felder zuordnen</v-btn
              >
            </template>

            <template slot="submit" slot-scope="{ submit }">
              <v-btn @click.prevent="submit" color="success"
                >Importieren!</v-btn
              >
            </template>
          </VuetifyCsvImport>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="purple"
          ></v-progress-circular>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DataService from "@/services/DataService";
import MandantenSelectbox from "@/components/clients/MandantenSelectbox";
import VuetifyCsvImport from "@/components/vuetify-csv-import";

export default {
  name: "ImportDialog",
  components: { VuetifyCsvImport, MandantenSelectbox },
  data: () => ({
    loading: false,
    headers: false,
    dialogStatus: false,
    parseCsv: null,
    fieldLabels: null,
  }),
  props: ["importdata", "mapFields", "importUrl"],
  methods: {
    importDialog() {
      this.dialogStatus = true;
    },
    closeDialog() {
      this.loading = false;
      this.dialogStatus = false;
      this.headers = false;
      this.parseCsv = null;
    },
    importData(data) {
      this.loading = true;
      DataService.apiClient
        .post(this.importUrl, { data: data, mandant: this.mandant })
        .then((response) => {
          if (response.data.failed.length == 0) {
            this.$notify({
              group: "dataline",
              type: "success",
              title: "Kontaktimport",
              text: "Kontakte wurden erfolgreich importiert.",
            });
          } else {
            this.$notify({
              group: "dataline",
              type: "warning",
              title: "Kontaktimport",
              text:
                "Es wurden nicht alle Kontakte importiert." +
                response.data.success +
                " von " +
                response.data.total +
                " Kontakte wurden importiert.",
            });
          }

          this.closeDialog();
          this.$store.dispatch("contact/fetchContactList");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Importfehler",
              text:
                "Kontakte konnten nicht importiert werden. " + error.message,
            });
          }
        });
    },
  },
  computed: {
    mandant() {
      return this.$store.getters["mandant/getWorkingMandant"];
    },
  },
};
</script>

<style scoped>
.importTable {
  padding: 4px;
}
</style>
